<template>
  <div>
    <ViewFormFarm/>
  </div>
</template>

<script>
  import ViewFormFarm from '@/components/formFarma/ViewFormFarm.vue';
  
  export default {
    components: {
      ViewFormFarm,
    }
  }
</script>