<template>
  <main>
    <div class="content-btn-addReturn">
    <router-link style="width: 0px" to="/dashboard">
      <button class="contacts-btn-return">
        {{ $t("sectionRegisters.backDashbord") }}
      </button>
    </router-link>  
    <router-link style="width: 0px" :to="{ name: 'addOrUpdateFormaFarma', params: { activeRegister } }">
      <button class="contacts-btn-add">
        {{ $t("sectionFormaFarma.addFarma") }}
      </button>
    </router-link>
    </div>
    <h1 class="titleViewContact">{{ $t("sectionFormaFarma.typeFarma") }}</h1>
    <label class="content-searchContact">
      <span>{{ $t("sectionFormaFarma.searchFarma") }}</span>
      <input v-model="search" type="text">
      <button @click="searchContact">{{ $t("sectionSiproquim.search") }}</button>
    </label>
    <table class="content-tableContact">
      <tr class="header-table">
        <th>{{ $t("sectionFormaFarma.description") }}</th>
      </tr>
      <tr class="items-table" v-for="(contacts, index) in stateContacts" :key="index">
        <td>{{ contacts.descricao }}</td>
        <span class="content-btn-contactEditOrDelete">
          <router-link :to="{ name: 'addOrUpdateFormaFarma', params: { activeEdit, contacts } }">
            <button class="editStyle"> 
              {{ $t("sectionRegisters.edit") }}
            </button>
          </router-link>
            <button class="deleteStyle" @click="deleteContacts(contacts)">
              {{ $t("sectionRegisters.delete") }}
            </button>
        </span>
      </tr>
    </table>
    <section class="content-pageContact">
      <button class="backPage" @click="backPage">Página Anterior</button>
      <button class="nextPage" @click="nextPage">Próxima Página</button>
    </section>
  </main>
</template>

<script>
//import axios from 'axios';
  export default {
    name: "ViewFormaFarma",
    data() {
      return {
        activeRegister: true,
        activeEdit: true,
        search: '',
        foundContact: '',
        PageCurrent: 1,
      }
    },

    mounted(){
      this.$store.dispatch('stateFormaFarma/totalFormaFarma');
    },  

      watch: {
      search() {
        this.$store.dispatch('stateFormaFarma/totalFormaFarma');
      }
    },

    computed: {
      stateContacts() {
        const { FormaFarma } = this.$store.state.stateFormaFarma;

        return FormaFarma;
      }
    }
  }
</script>


<style scoped>
  main {
   display: flex;
   flex-direction: column;
  }

  .content-btn-addReturn {
    display: flex;
  }
  .contacts-btn-return {
   padding: 2px;
    width: 150px;
    margin-left: 10px;
    border: 1.5px gray solid;
    border-radius: 5px;
  }

  .contacts-btn-add {
    padding: 2px;
    width: 150px;
    margin-left: 910px;
    border: 1.5px gray solid;
    border-radius: 5px;
  }

  .titleViewSipro {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }

  .content-searchContact {
    padding: 10px;
    /* border: 1px red solid; */
  }
  .content-searchContact span {
    font-weight: bold;
    font-size: 1.2em;
  }

  .content-searchContact input {
    margin-left: 5px;
    padding: 2px;
  }

  .content-searchContact button {
    margin-left: 4px;
    padding: 2.5px;
    border: 1.5px gray solid;
    border-radius: 5px;
  }

  .content-tableContact {
    border: 1px black solid;
  }

  .content-tableContact .header-table {
    font-weight: bold;
    font-size: 1.1em;
    border: 2px black solid;
  }

  .content-tableContact .items-table {
    /* margin: 50px; */
    border: 0.8px gray solid;
  }

  .content-tableContact .items-table:hover {
    background-color: gray;
    color: black;
    font-weight: bold;
  }

  .content-btn-contactEditOrDelete .editStyle {
    margin: 3px;
    padding: 1px;
    color: black;
    border: 1.5px black solid;
    border-radius: 5px;
  }

  .content-btn-contactEditOrDelete .deleteStyle {
    margin-left: 3px;
    padding: 1px;
    color: black;
    background-color: red;
    border: 1px black solid;
    border-radius: 5px;
  }

  .content-pageContact {
    text-align: center;
    margin-top: 10px;
  }
  .content-pageContact .backPage {
    padding: 3px;
    margin-right: 10px;
    font-size: 1em;
    font-weight: bold;
    background-color:turquoise;
    border: 1px solid;
    border-radius: 5px;
  }

  .content-pageContact .nextPage {
    padding: 3px;
    margin-left: 10px;
    font-size: 1em;
    font-weight: bold;
    background-color:turquoise;
    border: 1px solid;
    border-radius: 5px;
  }
</style>
